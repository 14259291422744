<template>
  <div class="container-fluid">
    <TableWithPagination
      ref="selfretails"
      :items="selfretails"
      :fields="selfretailsFields"
      :totalRows="selfretailsTotal"
      @change-params="onSelfretailsChangeParams"
      @row-selected="onSelfretailsRowSelected"
    >
      <template v-slot:header-actions>
        <div class="pl-3 justify-content-between row d-flex">
          <div class="col-auto">
            <b-form-checkbox v-model="switchEditMode" name="check-button" switch>
              <b v-if="!switchEditMode" class="pl-4 switch-label">Редактировать</b>
              <b v-if="switchEditMode" class="pl-4 switch-label">Вернуться к просмотру</b>
            </b-form-checkbox>
          </div>
          <div class="col-auto pr-4 d-flex align-items-center">
            <label class="pr-3 switch-label" for="switcher">Добавить информацию о производителе</label>
            <b-form-checkbox id="switcher" v-model="switchShowManufacturerInfoInCertificate" name="check-button" switch @change="switchManufacturerInfo()" />
          </div>
        </div>
      </template>
      <template v-slot:filters="{ filters, changeFilter }">
        <div class="row">
          <div class="col">
            <b-form-input v-model="filters.search" placeholder="Поиск" type="search" @input="changeFilter('search', $event)"></b-form-input>
          </div>
        </div>
      </template>
      <template v-slot:filters-actions v-if="isManufacturer && manufacturer.hasCertificate">
        <b-button variant="success" @click="onAddSelfRetail">Добавить Собственную розницу</b-button>
      </template>
    </TableWithPagination>

    <RightSidebar
      form="SelfRetailForm"
      :form-data="selfretailFormData"
      :mode="selfretailFormMode"
      :show="selfretailFormShow"
      @update:show="selfretailFormShow = $event;selfretailFormData = {};"
      @saved:form-data="onSavedSelfRetailForm"
    ></RightSidebar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { TableWithPagination, RightSidebar } from "@/components";

export default {
  name: "SelfRetailsOffices",
  components: {
    TableWithPagination,
    RightSidebar
  },
  data() {
    return {
      filters: {
        search: "",
      },
      selfretailsFields: [
        { key: "spotName", label: "Наименование", sortable: true },
        { key: "spotAddress", label: "Фактический адрес", sortable: true },
        { key: "spotAddressPhone", label: "Телефон", sortable: true },
        { key: "spotAccost", label: "Менеджер", sortable: true },
        { key: "isApproved", label: "Статус", sortable: true, formatter: (item) => (item ? "Подтверждена" : "Новая") },
        { key: "hasCertificate", label: "Сертификат", sortable: true, formatter: (item) => (item ? "Есть" : "Нет") },
      ],
      selectedSelfretail: null,
      selfretailFormData: {},
      selfretailFormMode: "ADD",
      selfretailFormShow: false,

      switchEditMode: false,
      switchShowManufacturerInfoInCertificate: false
    };
  },
  methods: {
    ...mapActions("regions", ["fetchRegions"]),
    ...mapActions("selfretails", [
      "fetchSelfRetails",
      "fetchSelfRetail",
      "createSelfRetail",
      "updateSelfRetail",
      "fetchManufacturerById",
      "toggleManufacturerInfo",
    ]),

    onSelfretailsChangeParams(params) {
      const fetchManufacturerById = (id) =>
        this.manufacturer.id
          ? new Promise((resolve) =>
              resolve(
                (function (m) {
                  return m;
                })(this.manufacturer)
              )
            )
          : this.fetchManufacturerById(id);
      fetchManufacturerById(this.partnerId).then(() => {
        this.fetchSelfRetails({ id: this.manufacturer.id, params }).then(() => {
          this.selfretailFormData = {},
          this.selfretailFormMode = "",
          this.selfretailFormShow = false,
          this.switchShowManufacturerInfoInCertificate = this.manufacturer.isShowManufacturerInfoInCertificate;
        });
      });
    },
    onSelfretailsRowSelected(selfretail) {
      this.selfretailFormData = Object.assign({}, selfretail);
      if (selfretail && this.switchEditMode) {
        this.selfretailFormMode = "EDIT"
        this.selfretailFormShow = true
      } else if (selfretail && selfretail.isApproved) this.$router.push(`/selfretails/${selfretail.id}/certificates`);
    },

    switchManufacturerInfo() {
      this.toggleManufacturerInfo(this.partnerId);
    },

    onAddSelfRetail() {
      this.selfretailFormData = {}
      this.selfretailFormMode = "ADD";
      this.selfretailFormShow = true;
    },
    onEditSelfRetail() {
      this.selfretailFormData = { ...this.selectedSelfRetail };
      this.selfretailFormMode = "EDIT";
      this.selfretailFormShow = true;
    },
    onSavedSelfRetailForm() {
      this.$refs.selfretails.reload();
    },
  },
  watch: {
    manufacturer(val) {
      this.switchShowManufacturerInfoInCertificate = val.isShowManufacturerInfoInCertificate;
    },
  },
  computed: {
    ...mapGetters(["userRole", "isManufacturer"]),
    ...mapState({ partnerId: (state) => state.auth.user.partnerId }),
    ...mapState("selfretails", {
      manufacturer: (state) => state.manufacturer,
      selfretails: (state) => state.selfretails,
      selfretailsTotal: (state) => state.selfretailsTotal,
    }),
  },
  created() {
    if (!this.manufacturer) this.fetchManufacturerById(this.partnerId);
    else this.switchShowManufacturerInfoInCertificate = this.manufacturer.isShowManufacturerInfoInCertificate;
  },
};
</script>

<style lang="scss">
.switch-label {
  font-weight: bold;
}
</style>
